import { Container } from "./styles";
import jsIcon from "../../assets/js-icon.svg";
import nodeIcon from "../../assets/node-icon.svg";
import reactIcon from "../../assets/react-icon.svg";
import typescriptIcon from "../../assets/typescript-icon.svg";
import ScrollAnimation from "react-animate-on-scroll";
export function About() {
  return (
    <Container id="about">
      <div className="about-text">
        <ScrollAnimation animateIn="fadeInLeft">
          <h2>Tentang Autoscript FighterTunnel</h2>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.1 * 1000}>
          <p>
            Dapatkan pengalaman terbaik dalam mengelola VPS dan layanan VPN Anda dengan fitur-fitur lengkap dan keamanan maksimal. Autoscript FT 2.0 LTS hadir untuk mempermudah manajemen server Anda, mulai dari pembuatan akun, pembatasan IP, hingga notifikasi multi-login!
          </p>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.2 * 1000} style={{ marginTop: "2rem", marginBottom: "2rem" }}>
          <h3>
            🌟 Kenapa Pilih Autoscript FT 2.0 LTS?
          </h3>
          <ul>
            <li>Kinerja Optimal: Dukungan multi-path Xray untuk akses lebih cepat dan stabil.</li>
            <li>Fitur Lengkap: Mulai dari pengaturan otomatis hingga kontrol penuh melalui Telegram bot.</li>
            <li>Keamanan Terjamin: Penghapusan otomatis akun yang sudah tidak aktif dan notifikasi untuk login ganda.</li>
            <li>Kemudahan Akses: Backup & restore data via web, serta fitur multi-server.</li>
            <li>OS Support: Autoscript ini mendukung semua versi Ubuntu dan Debian, memberikan fleksibilitas penuh sesuai kebutuhan server Anda!</li>
          </ul>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.3 * 1000}>
          <p>
            Jangan lewatkan kesempatan untuk meningkatkan performa server Anda! Hubungi kami sekarang dan rasakan perbedaannya!
          </p>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.4 * 1000}>
          <h3>Berikut adalah code utama kami:</h3>
        </ScrollAnimation>
        <div className="hard-skills">
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.13 * 1000}>
              <img src={reactIcon} alt="React" />
            </ScrollAnimation>
          </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.14 * 1000}>
              <img src={typescriptIcon} alt="Typescript" />
            </ScrollAnimation>
          </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.16 * 1000}>
              <img src={nodeIcon} alt="Node" />
            </ScrollAnimation>
          </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.19 * 1000}>
              <img src={jsIcon} alt="JavaScript" />
            </ScrollAnimation>
          </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.20 * 1000}>
              <img src="https://upload.wikimedia.org/wikipedia/commons/c/c3/Python-logo-notext.svg" alt="Python" />
            </ScrollAnimation>
          </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.21 * 1000}>
              <img src="https://upload.wikimedia.org/wikipedia/commons/4/4b/Bash_Logo_Colored.svg" alt="Bash" />
            </ScrollAnimation>
          </div>
        </div>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.5 * 1000}>
          <h3>🖥️ Server Information & Other Features</h3>
          <ul>
            <li>- Create Account</li>
            <li>- Delete Account</li>
            <li>- Renew Account</li>
            <li>- Check Account (lihat IP User yang terhubung)</li>
            <li>- Detail User List</li>
            <li>- Timezone: Asia/Jakarta (GMT +7)</li>
            <li>- Custom Autoreboot: On</li>
            <li>- Auto Delete Expired Accounts</li>
            <li>- VPS Settings</li>
            <li>- Admin Control</li>
            <li>- Backup & Restore Data Via Web</li>
            <li>- Simple BOT Telegram Multi Server</li>
            <li>- Full Orders For Various Services</li>
            <li>- Limit IP & Quota Xray</li>
            <li>- Multi-login Notification</li>
            <li>- Custom UUID/Password</li>
            <li>- Check Bandwidth Usage</li>
            <li>- Auto Backup On Time</li>
            <li>- Multi Path Xray</li>
            <li>- Banned/Unbanned SSH/OVPN</li>
            <li>- Auto Remove Account by Bandwidth Usage (Xray)</li>
          </ul>
        </ScrollAnimation>
      </div>
      <div className="about-image">
        <ScrollAnimation animateIn="fadeInRight" delay={0.20 * 1000}>
          <img src="https://github.com/FighterTunnel/rdp/raw/main/image/logo.png" alt="Autoscript FighterTunnel" />
        </ScrollAnimation>
      </div>
    </Container>
  )
}
